import React, { useState, useRef, useEffect } from 'react';
import { Copy, Check, Twitter, MessagesSquare, ExternalLink, Gamepad } from 'lucide-react';
import { Link } from 'react-router-dom';

const INITIAL_MESSAGES = [
  { id: 1, author: "Anon1", message: "¯\\_(ツ)_/¯ to the moon!", timestamp: "2024-01-28 12:34" },
  { id: 2, author: "Shrugging", message: "just bought a bag", timestamp: "2024-01-28 12:35" },
  { id: 3, author: "BaseAnon", message: "nice community", timestamp: "2024-01-28 12:36" }
];

const TOKEN_ADDRESS = "0x06A0a81Ef7d34c945AD2B07945bE1a75471C54FF";
const POOL_ADDRESS = "0xabd7d3dc758c7c64661042a52c9cb7778110e950";
const GECKO_API_BASE = "https://api.geckoterminal.com/api/v2";
const HEADERS = {
  'Accept': 'application/json;version=20230302'
};

const formatUSD = (value, decimals = 2) => {
  if (!value || isNaN(value)) return '$0.00';
  
  // For very small numbers (less than 1 cent), show exact value
  if (value < 0.01) {
    // Convert to string to avoid scientific notation
    const fixedStr = value.toFixed(11);
    // Remove trailing zeros after decimal
    const trimmed = fixedStr.replace(/\.?0+$/, '');
    return `$${trimmed}`;
  }
  
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  }).format(value);
};

const ShrugCoinLanding = () => {
  const [copied, setCopied] = useState(false);
  const [copiedContract, setCopiedContract] = useState(false);
  const [messages, setMessages] = useState(INITIAL_MESSAGES);
  const [newMessage, setNewMessage] = useState('');
  const [author, setAuthor] = useState('');
  const [marketData, setMarketData] = useState({
    price: '0',
    marketCap: '0',
    volume24h: '0',
    loading: true,
    error: null
  });
  
  const shrugEmoji = "¯\\_(ツ)_/¯";
  const contractAddress = POOL_ADDRESS;
  const chatEndRef = useRef(null);

  const fetchMarketData = async () => {
    try {
      // Fetch token data which includes price, market cap, and volume
      const tokenResponse = await fetch(
        `${GECKO_API_BASE}/networks/base/tokens/${TOKEN_ADDRESS}?include=top_pools`,
        { headers: HEADERS }
      );
      
      if (!tokenResponse.ok) throw new Error('Failed to fetch token data');
      
      const tokenData = await tokenResponse.json();
      const tokenAttributes = tokenData.data.attributes;
      
      // Extract price, market cap, and volume data
      const priceRaw = parseFloat(tokenAttributes.price_usd || 0);
      const marketCapRaw = parseFloat(tokenAttributes.fdv_usd || 0);
      const volume24hRaw = parseFloat(tokenAttributes.volume_usd?.h24 || 0);

      setMarketData({
        price: formatUSD(priceRaw, 11),
        marketCap: formatUSD(marketCapRaw, 2),
        volume24h: formatUSD(volume24hRaw, 2),
        loading: false,
        error: null
      });

      console.log('Fetched Data:', {
        priceRaw,
        marketCapRaw,
        volume24hRaw,
        rawTokenData: tokenAttributes
      });
    } catch (error) {
      console.error('Error fetching market data:', error);
      setMarketData(prev => ({
        ...prev,
        loading: false,
        error: 'Failed to load market data'
      }));
    }
  };

  useEffect(() => {
    fetchMarketData();
    const interval = setInterval(fetchMarketData, 30000); // Update every 30 seconds
    return () => clearInterval(interval);
  }, []);

  const copyShrug = () => {
    navigator.clipboard.writeText(shrugEmoji);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const copyContract = () => {
    navigator.clipboard.writeText(contractAddress);
    setCopiedContract(true);
    setTimeout(() => setCopiedContract(false), 2000);
  };

  const submitMessage = (e) => {
    e.preventDefault();
    if (newMessage.trim() && author.trim()) {
      const timestamp = new Date().toLocaleString();
      setMessages([...messages, {
        id: messages.length + 1,
        author: author.slice(0, 20),
        message: newMessage.slice(0, 200),
        timestamp
      }]);
      setNewMessage('');
      chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  return (
    <div className="min-h-screen bg-black text-white font-rajdhani relative">
      {/* Background Pattern */}
      <div 
        className="absolute inset-0 opacity-5"
        style={{
          backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
          backgroundSize: '60px 60px'
        }}
      />
      
      {/* Navigation */}
      <nav className="relative bg-gray-900/80 backdrop-blur-sm py-4">
        <div className="max-w-4xl mx-auto px-8 flex justify-between items-center">
          <div className="text-2xl font-bold text-yellow-400">{shrugEmoji}</div>
          <div className="flex gap-4">
            <Link 
              to="/slots" 
              className="bg-yellow-400 hover:bg-yellow-500 text-black px-4 py-2 rounded-lg transition-all inline-flex items-center gap-2"
            >
              <Copy size={20} />
              Play Slots
            </Link>
            <Link 
              to="/game" 
              className="bg-yellow-400 hover:bg-yellow-500 text-black px-4 py-2 rounded-lg transition-all inline-flex items-center gap-2"
            >
              <Gamepad size={20} />
              Play Game
            </Link>
          </div>
        </div>
      </nav>
      
      {/* Main Content */}
      <div className="relative max-w-4xl mx-auto p-8">
        {/* Hero Section with Copy Button */}
        <div className="text-center space-y-6 py-16">
          <div className="flex justify-center items-center gap-4">
            <h1 className="text-6xl font-bold mb-4 animate-bounce">
              SHRUG COIN {shrugEmoji}
            </h1>
            <button
              onClick={copyShrug}
              className="bg-yellow-400 hover:bg-yellow-500 text-black p-2 rounded-lg transition-all"
              title="Copy shrug emoji"
            >
              {copied ? <Check size={24} /> : <Copy size={24} />}
            </button>
          </div>
          <p className="text-xl mb-8 text-gray-300">Stay positive through the dips!</p>
          
          {/* Contract Address Section */}
          <div className="bg-gray-800/80 backdrop-blur-sm rounded-xl p-8 mt-8">
            <h2 className="text-2xl font-bold mb-4 text-yellow-400">Contract Address</h2>
            <div className="flex items-center justify-center gap-2 mb-4">
              <div className="font-rajdhani bg-gray-900/80 p-4 rounded-lg break-all">
                {TOKEN_ADDRESS}
              </div>
              <button
                onClick={copyContract}
                className="bg-yellow-400 hover:bg-yellow-500 text-black p-2 rounded-lg transition-all"
                title="Copy contract address"
              >
                {copiedContract ? <Check size={20} /> : <Copy size={20} />}
              </button>
            </div>
            <div className="flex justify-center gap-4">
              <a 
                href={`https://dexscreener.com/base/${POOL_ADDRESS}`}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center gap-2 text-yellow-400 hover:text-yellow-500 transition-colors"
              >
                View on DexScreener <ExternalLink size={16} />
              </a>
              <a 
                href={`https://www.geckoterminal.com/base/pools/${POOL_ADDRESS}`}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center gap-2 text-yellow-400 hover:text-yellow-500 transition-colors"
              >
                View on GeckoTerminal <ExternalLink size={16} />
              </a>
            </div>
          </div>

          {/* Features Grid */}
          <div className="grid md:grid-cols-2 gap-8 mt-16">
            <div className="bg-gray-800/80 backdrop-blur-sm rounded-xl p-6 hover:transform hover:scale-105 transition-all">
              <h3 className="text-xl font-bold mb-2 text-yellow-400">HODL WITH A SMILE</h3>
              <p className="text-gray-300">When others panic, we just shrug it off {shrugEmoji}</p>
            </div>
            <div className="bg-gray-800/80 backdrop-blur-sm rounded-xl p-6 hover:transform hover:scale-105 transition-all">
              <h3 className="text-xl font-bold mb-2 text-yellow-400">COMMUNITY DRIVEN</h3>
              <p className="text-gray-300">Built by shruggers, for shruggers</p>
            </div>
          </div>

          {/* Market Stats */}
          <div className="grid grid-cols-3 gap-4 mt-16">
            <div className="bg-gray-800/80 backdrop-blur-sm rounded-xl p-4">
              <h4 className="font-bold text-yellow-400">Price</h4>
              <p className="text-lg">
                {marketData.loading ? (
                  <span className="animate-pulse">Loading...</span>
                ) : marketData.error ? (
                  shrugEmoji
                ) : (
                  marketData.price
                )}
              </p>
            </div>
            <div className="bg-gray-800/80 backdrop-blur-sm rounded-xl p-4">
              <h4 className="font-bold text-yellow-400">Market Cap</h4>
              <p className="text-lg">
                {marketData.loading ? (
                  <span className="animate-pulse">Loading...</span>
                ) : marketData.error ? (
                  shrugEmoji
                ) : (
                  marketData.marketCap
                )}
              </p>
            </div>
            <div className="bg-gray-800/80 backdrop-blur-sm rounded-xl p-4">
              <h4 className="font-bold text-yellow-400">24h Volume</h4>
              <p className="text-lg">
                {marketData.loading ? (
                  <span className="animate-pulse">Loading...</span>
                ) : marketData.error ? (
                  shrugEmoji
                ) : (
                  marketData.volume24h
                )}
              </p>
            </div>
          </div>

          {/* Chat Box */}
          <div className="bg-gray-800/80 backdrop-blur-sm rounded-xl p-6 mt-16">
            <h3 className="text-xl font-bold mb-4 text-yellow-400 flex items-center gap-2">
              <MessagesSquare size={24} />
              Community Chat
            </h3>
            <div className="bg-gray-900/80 rounded-lg p-4 h-64 overflow-y-auto mb-4">
              {messages.map(msg => (
                <div key={msg.id} className="mb-4">
                  <div className="flex items-baseline gap-2">
                    <span className="font-bold text-yellow-400">{msg.author}</span>
                    <span className="text-xs text-gray-500">{msg.timestamp}</span>
                  </div>
                  <p className="text-gray-300">{msg.message}</p>
                </div>
              ))}
              <div ref={chatEndRef} />
            </div>
            <form onSubmit={submitMessage} className="flex flex-col gap-2">
              <input
                type="text"
                placeholder="Your name"
                value={author}
                onChange={(e) => setAuthor(e.target.value)}
                className="bg-gray-900/80 rounded-lg p-2 text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-yellow-400"
                maxLength={20}
              />
              <div className="flex gap-2">
                <input
                  type="text"
                  placeholder="Type your message..."
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  className="flex-1 bg-gray-900/80 rounded-lg p-2 text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-yellow-400"
                  maxLength={200}
                />
                <button
                  type="submit"
                  className="bg-yellow-400 hover:bg-yellow-500 text-black px-4 py-2 rounded-lg transition-all"
                >
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

{/* Footer */}
<footer className="relative bg-gray-900/80 backdrop-blur-sm py-8 mt-16">
        <div className="max-w-4xl mx-auto px-8">
          <div className="flex justify-center space-x-8">
            <a 
              href="https://x.com/Shrug_coin" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="text-gray-300 hover:text-yellow-400 transition-colors flex items-center gap-2"
            >
              <Twitter size={32} />
              <span className="hidden md:inline">Follow on X</span>
            </a>
            <a 
              href="https://t.me/asciishrug" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="text-gray-300 hover:text-yellow-400 transition-colors flex items-center gap-2"
            >
              <MessagesSquare size={32} />
              <span className="hidden md:inline">Join Telegram</span>
            </a>
          </div>
          <div className="text-center mt-6 text-gray-400">
            © 2024 Shrug Coin {shrugEmoji} | All rights reserved
          </div>
        </div>
      </footer>
    </div>
  );
};

export default ShrugCoinLanding;