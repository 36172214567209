import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ShrugCoinLanding from './components/ShrugCoinLanding';
import ShrugSlots from './components/ShrugSlots';
import ShrugGalaga from './components/ShrugGalaga';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<ShrugCoinLanding />} />
        <Route path="/slots" element={<ShrugSlots />} />
        <Route path="/game" element={<ShrugGalaga />} />
      </Routes>
    </Router>
  );
}

export default App;