import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

// Constants
const GAME_WIDTH = 800;
const GAME_HEIGHT = 600;
const PLAYER_SPEED = 5;
const BULLET_SPEED = 7;

// Enemy Types
const ENEMIES = {
  BASIC: { emoji: '👾', points: 100, health: 1, speed: 2 },
  MEDIUM: { emoji: '👿', points: 200, health: 2, speed: 3 },
  BOSS: { emoji: '👹', points: 300, health: 3, speed: 1.5 }
};

// Powerup Types
const POWERUPS = {
  TRIPLE_SHOT: { emoji: '💫', duration: 10000, color: '#ffd700' },
  SHIELD: { emoji: '🛡️', duration: 8000, color: '#3b82f6' },
  SPEED: { emoji: '⚡', duration: 7000, color: '#10b981' }
};

// Formation Patterns
const FORMATIONS = [
  // V formation
  [
    { x: 0, y: 0 }, { x: -50, y: -30 }, { x: 50, y: -30 },
    { x: -100, y: -60 }, { x: 100, y: -60 }
  ],
  // Line formation
  [
    { x: -100, y: 0 }, { x: -50, y: 0 }, { x: 0, y: 0 },
    { x: 50, y: 0 }, { x: 100, y: 0 }
  ],
  // Diamond formation
  [
    { x: 0, y: 0 }, { x: -50, y: -50 }, { x: 50, y: -50 },
    { x: 0, y: -100 }
  ]
];

// Particle Class
class Particle {
  constructor(x, y, color) {
    this.x = x;
    this.y = y;
    this.color = color;
    this.size = Math.random() * 3 + 2;
    const angle = Math.random() * Math.PI * 2;
    const speed = Math.random() * 4 + 2;
    this.dx = Math.cos(angle) * speed;
    this.dy = Math.sin(angle) * speed;
    this.alpha = 1;
    this.life = 1;
  }

  update() {
    this.x += this.dx;
    this.y += this.dy;
    this.life -= 0.02;
    this.alpha = this.life;
    this.size *= 0.99;
  }

  draw(ctx) {
    ctx.save();
    ctx.globalAlpha = this.alpha;
    ctx.fillStyle = this.color;
    ctx.beginPath();
    ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
    ctx.fill();
    ctx.restore();
  }
}

const ShrugGalaga = () => {
  // Refs and State
  const canvasRef = useRef(null);
  const [score, setScore] = useState(0);
  const [highScore, setHighScore] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [gameOver, setGameOver] = useState(false);
  const [level, setLevel] = useState(1);
  const [activePowerups, setActivePowerups] = useState([]);

  // Game State Ref
  const gameState = useRef({
    player: {
      x: GAME_WIDTH / 2 - 25,
      y: GAME_HEIGHT - 60,
      width: 50,
      height: 30,
      hasShield: false,
      hasTripleShot: false,
      speed: PLAYER_SPEED
    },
    bullets: [],
    enemies: [],
    powerups: [],
    particles: [],
    keys: {},
    animationFrame: null,
    spawnTimer: 0,
    waveTimer: 0,
    powerupTimer: 0
  });

  // Helper Functions
  const createExplosion = (x, y, color) => {
    for (let i = 0; i < 15; i++) {
      gameState.current.particles.push(new Particle(x, y, color));
    }
  };

  const spawnFormation = () => {
    const formation = FORMATIONS[Math.floor(Math.random() * FORMATIONS.length)];
    const baseX = Math.random() * (GAME_WIDTH - 200) + 100;
    const baseY = -50;

    formation.forEach(pos => {
      const enemyType = Object.values(ENEMIES)[Math.floor(Math.random() * 3)];
      gameState.current.enemies.push({
        x: baseX + pos.x,
        y: baseY + pos.y,
        width: 40,
        height: 30,
        ...enemyType,
        initialX: baseX + pos.x,
        initialY: baseY + pos.y,
        phase: Math.random() * Math.PI * 2
      });
    });
  };

  const spawnPowerup = () => {
    const powerupType = Object.values(POWERUPS)[Math.floor(Math.random() * 3)];
    gameState.current.powerups.push({
      x: Math.random() * (GAME_WIDTH - 30),
      y: -30,
      width: 30,
      height: 30,
      ...powerupType,
      speed: 2
    });
  };

  const activatePowerup = (type) => {
    const { player } = gameState.current;
    switch (type) {
      case POWERUPS.TRIPLE_SHOT.emoji:
        player.hasTripleShot = true;
        setTimeout(() => {
          player.hasTripleShot = false;
          setActivePowerups(prev => prev.filter(p => p !== POWERUPS.TRIPLE_SHOT.emoji));
        }, POWERUPS.TRIPLE_SHOT.duration);
        break;
      case POWERUPS.SHIELD.emoji:
        player.hasShield = true;
        setTimeout(() => {
          player.hasShield = false;
          setActivePowerups(prev => prev.filter(p => p !== POWERUPS.SHIELD.emoji));
        }, POWERUPS.SHIELD.duration);
        break;
      case POWERUPS.SPEED.emoji:
        player.speed = PLAYER_SPEED * 1.5;
        setTimeout(() => {
          player.speed = PLAYER_SPEED;
          setActivePowerups(prev => prev.filter(p => p !== POWERUPS.SPEED.emoji));
        }, POWERUPS.SPEED.duration);
        break;
      default:
        break;
    }
    setActivePowerups(prev => [...prev, type]);
  };

  const shoot = () => {
    const { player } = gameState.current;
    if (player.hasTripleShot) {
      [-1, 0, 1].forEach(offset => {
        gameState.current.bullets.push({
          x: player.x + player.width / 2 + (offset * 10),
          y: player.y,
          width: 4,
          height: 10,
          dx: offset * 0.5
        });
      });
    } else {
      gameState.current.bullets.push({
        x: player.x + player.width / 2,
        y: player.y,
        width: 4,
        height: 10,
        dx: 0
      });
    }
  };

  // Game Loop and Effects
  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let lastTime = 0;

    const gameLoop = (timestamp) => {
      if (!isPlaying) return;

      const deltaTime = timestamp - lastTime;
      lastTime = timestamp;

      // Spawn waves
      gameState.current.waveTimer += deltaTime;
      if (gameState.current.waveTimer > 3000 && gameState.current.enemies.length < 5) {
        spawnFormation();
        gameState.current.waveTimer = 0;
      }

      // Spawn powerups
      gameState.current.powerupTimer += deltaTime;
      if (gameState.current.powerupTimer > 10000) {
        spawnPowerup();
        gameState.current.powerupTimer = 0;
      }

      // Clear canvas
      ctx.fillStyle = 'black';
      ctx.fillRect(0, 0, GAME_WIDTH, GAME_HEIGHT);

      // Draw starfield
      ctx.fillStyle = 'white';
      for (let i = 0; i < 50; i++) {
        ctx.fillRect(
          Math.random() * GAME_WIDTH,
          Math.random() * GAME_HEIGHT,
          1,
          1
        );
      }

      // Update and draw particles
      gameState.current.particles = gameState.current.particles.filter(particle => {
        particle.update();
        particle.draw(ctx);
        return particle.life > 0;
      });

      // Update and draw enemies
      gameState.current.enemies.forEach((enemy, index) => {
        // Wave motion
        enemy.phase += 0.02;
        enemy.x = enemy.initialX + Math.sin(enemy.phase) * 50;
        enemy.y += enemy.speed;

        // Draw enemy
        ctx.font = '24px Arial';
        ctx.fillStyle = 'white';
        ctx.fillText(enemy.emoji, enemy.x, enemy.y);

        // Remove if off screen
        if (enemy.y > GAME_HEIGHT) {
          gameState.current.enemies.splice(index, 1);
        }
      });

      // Update and draw powerups
      gameState.current.powerups.forEach((powerup, index) => {
        powerup.y += powerup.speed;
        
        ctx.font = '24px Arial';
        ctx.fillStyle = powerup.color;
        ctx.fillText(powerup.emoji, powerup.x, powerup.y);

        if (powerup.y > GAME_HEIGHT) {
          gameState.current.powerups.splice(index, 1);
        }
      });

      // Update and draw bullets
      gameState.current.bullets.forEach((bullet, index) => {
        bullet.y -= BULLET_SPEED;
        bullet.x += bullet.dx || 0;
        
        ctx.fillStyle = 'yellow';
        ctx.fillRect(bullet.x, bullet.y, bullet.width, bullet.height);

        if (bullet.y < 0) {
          gameState.current.bullets.splice(index, 1);
        }

        // Check for collisions with enemies
        gameState.current.enemies.forEach((enemy, enemyIndex) => {
          if (bullet.x < enemy.x + enemy.width &&
              bullet.x + bullet.width > enemy.x &&
              bullet.y < enemy.y + enemy.height &&
              bullet.y + bullet.height > enemy.y) {
            
            enemy.health--;
            gameState.current.bullets.splice(index, 1);
            createExplosion(enemy.x + enemy.width / 2, enemy.y + enemy.height / 2, '#ff4444');

            if (enemy.health <= 0) {
              gameState.current.enemies.splice(enemyIndex, 1);
              setScore(prev => {
                const newScore = prev + enemy.points;
                if (newScore > highScore) {
                  setHighScore(newScore);
                }
                if (newScore >= level * 1000) {
                  setLevel(l => l + 1);
                }
                return newScore;
              });
            }
          }
        });
      });

      // Check for collisions with powerups
      gameState.current.powerups.forEach((powerup, index) => {
        if (powerup.x < gameState.current.player.x + gameState.current.player.width &&
            powerup.x + powerup.width > gameState.current.player.x &&
            powerup.y < gameState.current.player.y + gameState.current.player.height &&
            powerup.y + powerup.height > gameState.current.player.y) {
          
          activatePowerup(powerup.emoji);
          gameState.current.powerups.splice(index, 1);
        }
      });

      // Draw player
      const { player } = gameState.current;
      ctx.save();
      if (player.hasShield) {
        ctx.beginPath();
        ctx.arc(player.x + player.width / 2, player.y + player.height / 2, 35, 0, Math.PI * 2);
        ctx.strokeStyle = '#3b82f6';
        ctx.lineWidth = 2;
        ctx.stroke();
      }
      ctx.font = '24px Arial';
      ctx.fillStyle = 'yellow';
      ctx.fillText('¯\\_(ツ)_/¯', player.x, player.y + 20);
      ctx.restore();

      // Move player
      if (gameState.current.keys.ArrowLeft) {
        player.x = Math.max(0, player.x - player.speed);
      }
      if (gameState.current.keys.ArrowRight) {
        player.x = Math.min(GAME_WIDTH - player.width, player.x + player.speed);
      }

      // Check for collisions with player
      gameState.current.enemies.forEach(enemy => {
        if (!player.hasShield &&
            enemy.x < player.x + player.width &&
            enemy.x + enemy.width > player.x &&
            enemy.y < player.y + player.height &&
            enemy.y + enemy.height > player.y) {
          createExplosion(player.x + player.width / 2, player.y + player.height / 2, '#ffd700');
          setGameOver(true);
          setIsPlaying(false);
        }
      });

      gameState.current.animationFrame = requestAnimationFrame(gameLoop);
    };

    const handleKeyDown = (e) => {
      gameState.current.keys[e.key] = true;
      if (e.key === ' ' && isPlaying) {
        shoot();
      }
    };

    const handleKeyUp = (e) => {
      gameState.current.keys[e.key] = false;
    };

    // Attach Event Listeners and Start Game Loop
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    if (isPlaying) {
      gameState.current.animationFrame = requestAnimationFrame(gameLoop);
    }

    // Cleanup Function
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
      cancelAnimationFrame(gameState.current.animationFrame);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying, highScore, level]); // Dependencies

  // Start Game Function
  const startGame = () => {
    setScore(0);
    setLevel(1);
    setGameOver(false);
    setActivePowerups([]);
    gameState.current = {
      player: {
        x: GAME_WIDTH / 2 - 25,
        y: GAME_HEIGHT - 60,
        width: 50,
        height: 30,
        hasShield: false,
        hasTripleShot: false,
        speed: PLAYER_SPEED
      },
      enemies: [],
      bullets: [],
      powerups: [],
      particles: [],
      keys: {},
      animationFrame: null,
      spawnTimer: 0,
      waveTimer: 0,
      powerupTimer: 0
    };
    setIsPlaying(true);
  };

  // JSX Return
  return (
    <div className="min-h-screen bg-black text-white font-rajdhani relative">
      <div className="relative container mx-auto px-4 py-8 text-center">
        <h1 className="text-4xl md:text-6xl font-bold mb-8">Shrug Invaders</h1>
        
        <div className="max-w-4xl mx-auto bg-gray-800/80 backdrop-blur-sm rounded-xl p-8 mb-8">
          {/* Game Stats */}
          <div className="mb-4 flex justify-between items-center">
            <div className="space-y-2 text-left">
              <div className="text-xl font-bold text-yellow-400">Score: {score}</div>
              <div className="text-lg text-gray-400">High Score: {highScore}</div>
              <div className="text-lg text-yellow-400">Level: {level}</div>
            </div>

            {/* Active Powerups Display */}
            <div className="flex gap-2">
              {activePowerups.map((powerup, index) => (
                <div 
                  key={index}
                  className="bg-gray-900/80 p-2 rounded-lg text-2xl"
                  title={Object.entries(POWERUPS).find((entry) => entry[1].emoji === powerup)?.[0]}
                >
                  {powerup}
                </div>
              ))}
            </div>

            {/* Start/Restart Button */}
            {!isPlaying && (
              <button
                onClick={startGame}
                className="bg-yellow-400 hover:bg-yellow-500 text-black px-8 py-2 rounded-lg transition-all"
              >
                {gameOver ? 'Play Again' : 'Start Game'}
              </button>
            )}
          </div>

          {/* Game Canvas */}
          <canvas
            ref={canvasRef}
            width={GAME_WIDTH}
            height={GAME_HEIGHT}
            className="border border-gray-700 rounded-lg mx-auto bg-black"
          />

          {/* Game Over Screen */}
          {gameOver && (
            <div className="mt-4 space-y-4">
              <p className="text-2xl font-bold text-red-500">Game Over!</p>
              <p className="text-xl text-yellow-400">Final Score: {score}</p>
              {score === highScore && score > 0 && (
                <p className="text-lg text-green-400">New High Score!</p>
              )}
            </div>
          )}

          {/* Game Instructions */}
          <div className="mt-4 text-gray-400">
            <h3 className="font-bold text-yellow-400 mb-2">How to Play:</h3>
            <div className="grid grid-cols-2 gap-4 text-left">
              <div>
                <p className="font-bold mb-1">Controls:</p>
                <ul className="space-y-1">
                  <li>• Arrow Keys to move</li>
                  <li>• Space to shoot</li>
                </ul>
              </div>
              <div>
                <p className="font-bold mb-1">Power-ups:</p>
                <ul className="space-y-1">
                  <li>• {POWERUPS.TRIPLE_SHOT.emoji} Triple Shot</li>
                  <li>• {POWERUPS.SHIELD.emoji} Shield</li>
                  <li>• {POWERUPS.SPEED.emoji} Speed Boost</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* Back to Home Link */}
        <Link 
          to="/"
          className="text-yellow-400 hover:text-yellow-500 transition-colors"
        >
          ← Back to Home
        </Link>
      </div>
    </div>
  );
}; // End of ShrugGalaga component

export default ShrugGalaga;
