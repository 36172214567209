import React, { useState, useEffect } from 'react';
import { Contract, BrowserProvider } from 'ethers';
import { Loader2, Coins } from 'lucide-react';
import { Link } from 'react-router-dom';

// Import the ABI of the ShrugSlots contract and the SHRUG token contract
import ShrugSlotsABI from './abis/ShrugSlots.json';
import ShrugTokenABI from './abis/ShrugToken.json';

// Constants
const SHRUG_SLOTS_ADDRESS = '0xE4808FaB5F4e15543BAa242203A582Da27813e08';
const SHRUG_TOKEN_ADDRESS = '0x06A0a81Ef7d34c945AD2B07945bE1a75471C54FF';
const SPIN_COST = 100; // Cost per spin in SHRUG tokens (raw amount)
const SYMBOLS = ['🚀', '💎', '🌙', '🎰', '💫', '¯\\_(ツ)_/¯'];
const WIN_MULTIPLIER = 25; // 25x multiplier from contract
const WIN_CHANCE = 2; // 2% chance to win from contract (1 in 50)

// Function to format SHRUG balance (with 18 decimals)
const formatShrugBalance = (amount) => {
  const decimals = 18;
  const divisor = BigInt(10) ** BigInt(decimals);
  const whole = BigInt(amount) / divisor;
  const fraction = BigInt(amount) % divisor;
  const fractionStr = fraction.toString().padStart(decimals, '0').replace(/0+$/, '');
  return fractionStr ? `${whole}.${fractionStr}` : whole.toString();
};

// Function to format other balances (without decimals)
const formatDisplayAmount = (amount) => {
  return Number(amount).toLocaleString();
};

function ShrugSlots() {
  // Basic state
  const [reels, setReels] = useState(Array(3).fill('🎰'));
  const [spinning, setSpinning] = useState(false);
  const [win, setWin] = useState(false);
  const [userAddress, setUserAddress] = useState(null);
  const [network, setNetwork] = useState(null);
  
  // Contract and balance state
  const [shrugToken, setShrugToken] = useState(null);
  const [shrugSlotsContract, setShrugSlotsContract] = useState(null);
  const [shrugBalance, setShrugBalance] = useState('0');
  const [internalBalance, setInternalBalance] = useState('0');
  const [prizePool, setPrizePool] = useState('0');
  const [unclaimedPrize, setUnclaimedPrize] = useState('0');
  
  // Game state
  const [numberOfSpins, setNumberOfSpins] = useState(1);
  const [remainingSpins, setRemainingSpins] = useState(0);
  const [isSpinningQueue, setIsSpinningQueue] = useState(false);
  const [spinResults, setSpinResults] = useState([]);
  
  // Deposit and approval state
  const [depositAmount, setDepositAmount] = useState('');
  const [needsApproval, setNeedsApproval] = useState(true);
  
  // Faucet state
  const [canClaimFaucet, setCanClaimFaucet] = useState(false);
  const [faucetCooldown, setFaucetCooldown] = useState(0);

  // Function to connect wallet
  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const provider = new BrowserProvider(window.ethereum);
        await provider.send('eth_requestAccounts', []);
        const signer = await provider.getSigner();
        const address = await signer.getAddress();
        setUserAddress(address);

        const network = await provider.getNetwork();
        setNetwork(network);

        const shrugTokenContract = new Contract(SHRUG_TOKEN_ADDRESS, ShrugTokenABI, signer);
        setShrugToken(shrugTokenContract);

        const shrugSlots = new Contract(SHRUG_SLOTS_ADDRESS, ShrugSlotsABI, signer);
        setShrugSlotsContract(shrugSlots);

        await fetchBalances(shrugTokenContract, shrugSlots, address);
      } catch (error) {
        console.error('Wallet connection error:', error);
        alert('Failed to connect wallet.');
      }
    } else {
      alert('Please install MetaMask to use this application.');
    }
  };

  // Function to fetch balances and allowance
  const fetchBalances = async (tokenContract, slotsContract, address) => {
    try {
      // Get internal balance directly first
      const internalBalRaw = await slotsContract.getUserBalance(address);
      setInternalBalance(internalBalRaw.toString());

      // Then get other balances
      const [
        balanceRaw,
        prizePoolAmountRaw,
        unclaimedPrizeAmountRaw,
        lastFaucetTimeRaw,
      ] = await Promise.all([
        tokenContract.balanceOf(address),
        slotsContract.getPrizePoolBalance(),
        slotsContract.getUserPrize(address),
        slotsContract.lastFaucetClaimTime(address),
      ]);

      const lastFaucetTime = Number(lastFaucetTimeRaw);
      const currentTime = Math.floor(Date.now() / 1000);
      const claimInterval = 24 * 60 * 60;
      const timeSinceLastClaim = currentTime - lastFaucetTime;
      const canClaim = timeSinceLastClaim >= claimInterval;
      const cooldown = canClaim ? 0 : claimInterval - timeSinceLastClaim;

      setCanClaimFaucet(canClaim);
      setFaucetCooldown(cooldown);

      setShrugBalance(balanceRaw.toString());
      setPrizePool(prizePoolAmountRaw.toString());
      setUnclaimedPrize(unclaimedPrizeAmountRaw.toString());

      if (depositAmount) {
        const allowanceRaw = await tokenContract.allowance(address, SHRUG_SLOTS_ADDRESS);
        setNeedsApproval(BigInt(allowanceRaw) < BigInt(depositAmount));
      }
    } catch (error) {
      console.error('Error fetching balances:', error);
    }
  };

  // Function to handle approval
  const handleApprove = async () => {
    try {
      const amountToApprove = depositAmount.toString();
      const approveTx = await shrugToken.approve(SHRUG_SLOTS_ADDRESS, amountToApprove);
      await approveTx.wait();
      setNeedsApproval(false);
      alert(`Approved ${depositAmount} SHRUG successfully!`);
    } catch (error) {
      console.error('Approval error:', error);
      alert(`Error: ${error.reason || error.message}`);
    }
  };

  // Function to handle deposit
  const handleDeposit = async () => {
    try {
      const amountInTokens = depositAmount.toString();
      const depositTx = await shrugSlotsContract.depositTokens(amountInTokens);
      const receipt = await depositTx.wait();

      if (receipt.status === 1) {
        await fetchBalances(shrugToken, shrugSlotsContract, userAddress);
        alert(`Deposited ${depositAmount} SHRUG successfully!`);
      }

      setDepositAmount('');
    } catch (error) {
      console.error('Deposit error:', error);
      alert(`Error: ${error.reason || error.message}`);
    }
  };

  // Function to handle withdraw
  const handleWithdraw = async () => {
    try {
      if (BigInt(internalBalance) <= 0n) return;

      const amount = internalBalance.toString();
      const withdrawTx = await shrugSlotsContract.withdrawTokens(amount);
      await withdrawTx.wait();

      await fetchBalances(shrugToken, shrugSlotsContract, userAddress);
      alert(`Withdrew ${formatDisplayAmount(amount)} SHRUG successfully!`);
    } catch (error) {
      console.error('Withdraw error:', error);
      alert(`Error: ${error.reason || error.message}`);
    }
  };

  // Function to claim prize
const claimPrize = async () => {
    try {
      const claimTx = await shrugSlotsContract.claimPrize();
      await claimTx.wait();
  
      await fetchBalances(shrugToken, shrugSlotsContract, userAddress);
      setWin(false); // Reset win state after successful claim
      alert('Prize claimed successfully!');
    } catch (error) {
      console.error('Prize claim error:', error);
      alert(`Error: ${error.reason || error.message}`);
    }
  };

  // Function to claim faucet
  const claimFaucet = async () => {
    try {
      const faucetTx = await shrugSlotsContract.faucet();
      await faucetTx.wait();
      await fetchBalances(shrugToken, shrugSlotsContract, userAddress);
      alert('Faucet claimed successfully!');
    } catch (error) {
      console.error('Faucet claim error:', error);
      alert(`Error: ${error.reason || error.message}`);
    }
  };

  // Function to handle spins
const spin = async () => {
    if (
      spinning ||
      BigInt(internalBalance) < BigInt(SPIN_COST) * BigInt(numberOfSpins) ||
      !shrugSlotsContract
    )
      return;
  
    setSpinning(true);
    setWin(false);
    setSpinResults([]);
    
    try {
      // Execute spins
      const playTx = await shrugSlotsContract.play(numberOfSpins, {
        gasLimit: (100000n + 50000n * BigInt(numberOfSpins)).toString(),
      });
      
      // Wait for transaction and get receipt
      const receipt = await playTx.wait();
      
      // Find GamePlayed event in the receipt
      const gamePlayedEvent = receipt.events?.find(
        (e) => e.event === 'GamePlayed'
      );
      
      // Get total prize won from the event
      const totalPrizeWon = gamePlayedEvent ? 
        BigInt(gamePlayedEvent.args.totalPrizeWon) : 0n;

      // Start visualizing spins
      setRemainingSpins(numberOfSpins);
      setIsSpinningQueue(true);

      // Process each spin visualization
      for (let i = 0; i < numberOfSpins; i++) {
        // Start spin animation
        const spinInterval = setInterval(() => {
          setReels((prev) =>
            prev.map(() => SYMBOLS[Math.floor(Math.random() * SYMBOLS.length)])
          );
        }, 100);

        await new Promise(resolve => setTimeout(resolve, 2000));
        clearInterval(spinInterval);

        // Generate final reels
        const finalReels = Array(3)
          .fill('')
          .map(() => SYMBOLS[Math.floor(Math.random() * SYMBOLS.length)]);
        
        setReels(finalReels);
        
        // Calculate if this spin was part of the winning spins
        const prizePerWin = BigInt(SPIN_COST) * BigInt(WIN_MULTIPLIER);
        const spinIndex = Math.floor(Number(totalPrizeWon) / Number(prizePerWin));
        const isWinner = i < spinIndex;
        
        // Add result to spin results
        setSpinResults(prev => [...prev, { 
          reels: finalReels, 
          isWinner,
          prizeAmount: isWinner ? Number(prizePerWin) : 0 
        }]);
        
        setRemainingSpins(prev => prev - 1);
        
        if (i < numberOfSpins - 1) {
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
      }

      // Update all balances
      await fetchBalances(shrugToken, shrugSlotsContract, userAddress);
      
      setIsSpinningQueue(false);
      setSpinning(false);
      
      // Set win state based on if any prize was won
      setWin(totalPrizeWon > 0n);
      
    } catch (error) {
      console.error('Error during spin:', error);
      setSpinning(false);
      setIsSpinningQueue(false);
      setRemainingSpins(0);
      alert(`Error: ${error.reason || error.message}`);
    }
  };

  // Format time function
  const formatTime = (seconds) => {
    const hours = Math.floor(Number(seconds) / 3600).toString().padStart(2, '0');
    const minutes = Math.floor((Number(seconds) % 3600) / 60).toString().padStart(2, '0');
    const secs = Math.floor(Number(seconds) % 60).toString().padStart(2, '0');
    return `${hours}:${minutes}:${secs}`;
  };

  // Helper function to shorten address
  const shortenAddress = (address) => `${address.slice(0, 6)}...${address.slice(-4)}`;

  // Update balances when dependencies change
  useEffect(() => {
    if (userAddress && shrugToken && shrugSlotsContract) {
      fetchBalances(shrugToken, shrugSlotsContract, userAddress);
    }
  }, [userAddress, shrugToken, shrugSlotsContract, depositAmount]);

  // Periodically update balances
  useEffect(() => {
    let balanceInterval;
    if (userAddress && shrugToken && shrugSlotsContract) {
      balanceInterval = setInterval(() => {
        fetchBalances(shrugToken, shrugSlotsContract, userAddress);
      }, 1000);
    }
    return () => {
      if (balanceInterval) {
        clearInterval(balanceInterval);
      }
    };
  }, [userAddress, shrugToken, shrugSlotsContract]);

  // Event listeners for contract events
  useEffect(() => {
    if (shrugSlotsContract && userAddress) {
      const depositFilter = shrugSlotsContract.filters.TokensDeposited(userAddress);
      const withdrawFilter = shrugSlotsContract.filters.TokensWithdrawn(userAddress);
      const gameFilter = shrugSlotsContract.filters.GamePlayed(userAddress);
      const prizeFilter = shrugSlotsContract.filters.PrizeWon(userAddress);

      const updateBalances = () => {
        fetchBalances(shrugToken, shrugSlotsContract, userAddress);
      };

      shrugSlotsContract.on(depositFilter, updateBalances);
      shrugSlotsContract.on(withdrawFilter, updateBalances);
      shrugSlotsContract.on(gameFilter, updateBalances);
      shrugSlotsContract.on(prizeFilter, updateBalances);

      return () => {
        shrugSlotsContract.off(depositFilter, updateBalances);
        shrugSlotsContract.off(withdrawFilter, updateBalances);
        shrugSlotsContract.off(gameFilter, updateBalances);
        shrugSlotsContract.off(prizeFilter, updateBalances);
      };
    }
  }, [shrugSlotsContract, userAddress, shrugToken]);

  // Handle wallet events
  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', () => {
        setUserAddress(null);
        setShrugToken(null);
        setShrugSlotsContract(null);
        connectWallet();
      });
      window.ethereum.on('chainChanged', () => {
        window.location.reload();
      });
    }
  }, []);

  // Faucet cooldown timer
  useEffect(() => {
    let timer;
    if (!canClaimFaucet && faucetCooldown > 0) {
      timer = setInterval(() => {
        setFaucetCooldown((prev) => (prev > 0 ? prev - 1 : 0));
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [canClaimFaucet, faucetCooldown]);

  return (
    <div className="min-h-screen bg-black text-white font-rajdhani relative">
      {/* Background Pattern */}
      <div 
        className="absolute inset-0 opacity-5"
        style={{
          backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
          backgroundSize: '60px 60px'
        }}
      />

      <div className="relative container mx-auto px-4 py-16 text-center">
        <h1 className="text-4xl md:text-6xl font-bold mb-8">Shrug Slots</h1>

        {/* Game Info */}
        <div className="mb-8 text-gray-400">
          <p>Win Chance: {WIN_CHANCE}% | Win Multiplier: {WIN_MULTIPLIER}x</p>
          <p>Cost per Spin: {SPIN_COST} SHRUG</p>
        </div>

        {/* Wallet Connection */}
        {!userAddress ? (
          <button
            onClick={connectWallet}
            className="mb-8 px-6 py-2 rounded-lg bg-blue-500 hover:bg-blue-600 text-white font-bold"
          >
            Connect Wallet
          </button>
        ) : (
          <>
            {/* Wallet Info */}
            <div className="mb-4 text-sm text-gray-400">
              Connected: {shortenAddress(userAddress)} on {network?.name}
            </div>

            {/* Balances Display */}
            <div className="flex flex-wrap justify-center gap-8 mb-8">
              <div className="bg-gray-800/80 backdrop-blur-sm rounded-xl p-4 w-64">
                <div className="text-gray-400 mb-1">SHRUG Balance</div>
                <div className="text-2xl font-bold flex items-center justify-center gap-2">
                  <Coins className="text-yellow-400" size={24} />
                  {formatShrugBalance(shrugBalance)} ¯\_(ツ)_/¯
                </div>
              </div>

              <div className="bg-gray-800/80 backdrop-blur-sm rounded-xl p-4 w-64">
                <div className="text-gray-400 mb-1">Internal Balance</div>
                <div className="text-2xl font-bold text-yellow-400 flex items-center justify-center gap-2">
                  <Coins className="text-yellow-400" size={24} />
                  {formatDisplayAmount(internalBalance)} ¯\_(ツ)_/¯
                </div>
              </div>

              <div className="bg-gray-800/80 backdrop-blur-sm rounded-xl p-4 w-64">
                <div className="text-gray-400 mb-1">Prize Pool</div>
                <div className="text-2xl font-bold text-yellow-400 flex items-center justify-center gap-2">
                  <Coins className="text-yellow-400" size={24} />
                  {formatDisplayAmount(prizePool)} ¯\_(ツ)_/¯
                </div>
              </div>
            </div>

            {/* Deposit and Withdraw */}
            <div className="flex flex-wrap justify-center gap-8 mb-8">
              <div className="bg-gray-800/80 backdrop-blur-sm rounded-xl p-4 w-64">
                <div className="text-gray-400 mb-1">Deposit Tokens</div>
                <input
                  type="number"
                  value={depositAmount}
                  onChange={(e) => setDepositAmount(e.target.value)}
                  placeholder="Amount"
                  className="w-full mb-2 px-2 py-1 rounded bg-gray-700 text-white"
                />
                {depositAmount ? (
                  needsApproval ? (
                    <button
                      onClick={handleApprove}
                      className="w-full px-4 py-2 rounded bg-green-500 hover:bg-green-600 text-white font-bold"
                    >
                      Approve
                    </button>
                  ) : (
                    <button
                      onClick={handleDeposit}
                      className="w-full px-4 py-2 rounded bg-blue-500 hover:bg-blue-600 text-white font-bold"
                    >
                      Deposit
                    </button>
                  )
                ) : (
                  <button
                    disabled
                    className="w-full px-4 py-2 rounded bg-gray-500 cursor-not-allowed text-white font-bold"
                  >
                    Deposit
                  </button>
                )}
              </div>

              <div className="bg-gray-800/80 backdrop-blur-sm rounded-xl p-4 w-64">
                <div className="text-gray-400 mb-1">Withdraw Tokens</div>
                <div className="text-lg mb-2">
                  Available: {formatDisplayAmount(internalBalance)} ¯\_(ツ)_/¯
                </div>
                <button
                  onClick={handleWithdraw}
                  disabled={BigInt(internalBalance) <= 0n}
                  className={`w-full px-4 py-2 rounded ${
                    BigInt(internalBalance) <= 0n
                      ? 'bg-gray-500 cursor-not-allowed'
                      : 'bg-red-500 hover:bg-red-600'
                  } text-white font-bold`}
                >
                  Withdraw All
                </button>
              </div>
            </div>

            {/* Unclaimed Prize */}
            {BigInt(unclaimedPrize) > 0n && (
              <div className="mb-4">
                <div className="text-xl text-green-400 font-bold">
                  Unclaimed prize: {formatDisplayAmount(unclaimedPrize)} ¯\_(ツ)_/¯
                </div>
                <button
                  onClick={claimPrize}
                  className="mt-2 px-6 py-2 rounded-lg bg-green-500 hover:bg-green-600 text-white font-bold"
                >
                  Claim Prize
                </button>
              </div>
            )}

            {/* Faucet */}
            {canClaimFaucet ? (
              <button
                onClick={claimFaucet}
                className="mb-8 px-6 py-2 rounded-lg bg-blue-500 hover:bg-blue-600 text-white font-bold"
              >
                Claim 300 SHRUG from Faucet
              </button>
            ) : (
              <div className="mb-8 text-gray-400">
                Faucet available in: {formatTime(faucetCooldown)}
              </div>
            )}

            {/* Slot Machine */}
            <div className="max-w-3xl mx-auto bg-gray-800/80 backdrop-blur-sm rounded-xl p-8 mb-8">
              <div className="flex justify-center gap-4 mb-8">
                {reels.map((symbol, index) => (
                  <div
                    key={index}
                    className="w-40 h-24 bg-gray-900/80 rounded-lg flex items-center justify-center text-3xl whitespace-nowrap"
                    style={{ minWidth: '160px' }}
                  >
                    {symbol}
                  </div>
                ))}
              </div>

              {/* Spin Queue Progress */}
              {isSpinningQueue && (
                <div className="mb-4 text-yellow-400">
                  Remaining Spins: {remainingSpins}
                </div>
              )}

              {/* Spin Results Log */}
              {spinResults.length > 0 && (
                <div className="mb-4 max-h-40 overflow-y-auto bg-gray-900/80 p-4 rounded-lg">
                  {spinResults.map((result, index) => (
                    <div
                      key={index}
                      className={`mb-2 ${result.isWinner ? 'text-yellow-400' : 'text-gray-400'}`}
                    >
                      Spin {index + 1}: {result.reels.join(' ')} 
                      {result.isWinner && ` 🏆 Winner! (${formatDisplayAmount(result.prizeAmount)} SHRUG)`}
                    </div>
                  ))}
                </div>
              )}

              {/* Number of Spins Input */}
              <div className="mb-4 flex items-center justify-center gap-2">
                <input
                  type="number"
                  value={numberOfSpins}
                  onChange={(e) => setNumberOfSpins(Math.max(1, parseInt(e.target.value) || 1))}
                  min="1"
                  className="w-20 px-2 py-1 rounded bg-gray-700 text-white text-center"
                />
                <span className="text-gray-400">Number of Spins</span>
              </div>

              <button
                onClick={spin}
                disabled={
                  spinning ||
                  BigInt(internalBalance) < BigInt(SPIN_COST) * BigInt(numberOfSpins)
                }
                className={`
                  px-8 py-4 rounded-lg text-xl font-bold transition-all
                  ${
                    spinning ||
                    BigInt(internalBalance) < BigInt(SPIN_COST) * BigInt(numberOfSpins)
                      ? 'bg-gray-600 cursor-not-allowed'
                      : 'bg-yellow-400 hover:bg-yellow-500 text-black'
                  }
                `}
              >
                {spinning ? (
                  <span className="flex items-center justify-center gap-2">
                    <Loader2 className="animate-spin" />
                    {isSpinningQueue ? `Spinning... (${remainingSpins} remaining)` : 'Processing...'}
                  </span>
                ) : (
                  `SPIN ${numberOfSpins} Time(s) (Cost: ${
                    SPIN_COST * numberOfSpins
                  } ¯\\_(ツ)_/¯)`
                )}
              </button>

              {/* Win Message */}
              {win && (
                <div className="mt-8 text-2xl font-bold text-yellow-400 animate-bounce">
                  🎉 WINNER! Check your unclaimed prize! 🎉
                </div>
              )}
            </div>
          </>
        )}

        {/* Back to Home Link */}
        <Link
          to="/"
          className="text-yellow-400 hover:text-yellow-500 transition-colors"
        >
          ← Back to Home
        </Link>
      </div>
    </div>
  );
}

export default ShrugSlots;